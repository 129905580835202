import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../dashboard/_redux/statistics/statisticsActions";
import SmsGraph from "./partials/SmsGraph";
import SpaceGraph from "../dashboard/partials/SpaceGraph";
import ArchivedSpaceGraph from "../dashboard/partials/ArchivedSpaceGraph";
import SignedDocumentsGraph from "./partials/SignedDocumentsGraph";
import DocumentsList from "./partials/DocumentsList";
import {
    getDealerLabelForLegalEntity,
    getDealerLegalEntityFromHst,
    getUniqueDealersListForUser,
    getUserWriteBrands,
    userCanSeeAllDealers,
    userHasHst,
    userCanChangeLegalEntity,
    userHasWritePermissionOverBrand
} from "../../../_intesys/_helpers";
import BrandDocumentsButtons from "./partials/BrandDocumentsButtons";
import {DocumentsUIProvider} from "../../modules/App/pages/documents/DocumentsUIContext";
import DocumentEditDialog from "../../modules/App/pages/documents/document-edit-dialog/DocumentEditDialog";
import DocumentDeleteDialog from "../../modules/App/pages/documents/document-delete-dialog/DocumentDeleteDialog";
import DocumentQrCodeDialog from "../../modules/App/pages/documents/document-qr-code-dialog/DocumentQrCodeDialog";
import {documentsSlice} from "../../modules/App/_redux/documents/documentsSlice";
import {Formik, Form, Field} from "formik";
import {Card, CardBody, ReactSelect} from "../../../_intesys/_partials/controls";
import {injectIntl} from "react-intl";

function Dashboard({history, intl}) {
    const dispatch = useDispatch();

    const {user, folderTypesExt, dealers} = useSelector(
        (state) => ({
            user: state.auth.user,
            folderTypesExt: state.configurations.folderTypesExt,
            dealers: state.dealers.dealers
        }),
        shallowEqual
    );

    const [showDealerStatistics, setShowDealerStatistics] = useState(false);
    const [selectedDealer, setSelectedDealer] = useState(null);

    useEffect(() => {
        if (user && folderTypesExt && dealers) {
            let dealerLegalEntity = undefined;
            if ((userCanSeeAllDealers(user) || userCanChangeLegalEntity(user, dealers)) && selectedDealer) {
                dealerLegalEntity = selectedDealer.legal_entity;
            } else if (!userCanSeeAllDealers(user) && user.hstList.length > 0 && typeof dealers === 'object') {
                dealerLegalEntity = getDealerLegalEntityFromHst(dealers, user.hstList[0])
            }
            dispatch(actions.readStatistics(dealerLegalEntity, folderTypesExt));
        }
    }, [dispatch, user, folderTypesExt, selectedDealer, dealers]);

    const brands = getUserWriteBrands(user);
    const documentsUIEvents = {
        openShowDocumentPage: (id) => {
            dispatch(documentsSlice.actions.resetItemState(undefined));
            history.push(`/app/documents/${id}`);
        },
    };

    const [createButtonsCounter, setCreateButtonsCounter] = useState(0);

    useEffect(() => {
        let counter = 0;
        for (let i = 0; i < brands.length; i++) {
            const brand = brands[i];
            if (userHasWritePermissionOverBrand(user, brand)) {
                counter++
            }
        }
        setCreateButtonsCounter(counter);
    }, [user, brands]);

    const showLegalEntitySelector = (userCanSeeAllDealers(user) || userCanChangeLegalEntity(user, dealers));

    return <>
        {(createButtonsCounter > 0) && <div className="row">
            {brands.map((brand) =>
                <div className="col-lg-6 col-xxl-3" key={brand}>
                    <BrandDocumentsButtons history={history} brand={brand}/>
                </div>
            )}
        </div>}

        <div className="row">
            <div className={"col-12 " + (showLegalEntitySelector ? "gutter-b" : "")}>
                <hr/>
            </div>
        </div>

        {showLegalEntitySelector && <Card>
            {/*<Card>*/}
            <CardBody className={"d-flex flex-column"}>
                <div className="row">
                    <div className="col-12">
                        <Formik
                            enableReinitialize={true}
                            initialValues={{legalEntity: null}}
                            // validationSchema={LicenseEditSchema}
                            // onSubmit={(values) => {
                            //     saveLicense(values);
                            // }}
                        >
                            {({handleSubmit, values, form, handleChange, setFieldValue}) => (
                                <>
                                    <Form className="form form-label-right">
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <Field
                                                    id="legalEntity"
                                                    name="legalEntity"
                                                    component={ReactSelect}
                                                    withFeedbackLabel={false}
                                                    label={intl.formatMessage({id: "DASHBOARD.FIELDS.LEGALENTITY.LABEL"})}
                                                    placeholder={intl.formatMessage({id: "DASHBOARD.FIELDS.LEGALENTITY.PLACEHOLDER"})}
                                                    initialFeedBackLabel={intl.formatMessage({id: "DASHBOARD.FIELDS.LEGALENTITY.FEEDBACK"})}
                                                    options={getUniqueDealersListForUser(user, dealers)}
                                                    // options={dealers}
                                                    getOptionLabel={opt => getDealerLabelForLegalEntity(opt)}
                                                    getOptionValue={opt => opt.legal_entity}
                                                    onChangeFunction={
                                                        (selectedOption) => {
                                                            let filteredValue = undefined;
                                                            if (selectedOption) {
                                                                filteredValue = selectedOption.legal_entity;
                                                                setShowDealerStatistics(true)
                                                                setSelectedDealer(selectedOption)
                                                            } else {
                                                                setShowDealerStatistics(false)
                                                                setSelectedDealer(null)
                                                            }

                                                            return filteredValue;
                                                        }
                                                    }
                                                    valueFunction={
                                                        (options, field) => {
                                                            if (!options) {
                                                                return undefined;
                                                            }
                                                            if (field.value) {
                                                                return options.find((dealer) => dealer.legal_entity === field.value);
                                                            }
                                                            return undefined;
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </CardBody>
        </Card>
        }

        <div className="row">
            <div className="col-lg-12 col-xxl-12">
                <DocumentsUIProvider
                    documentsUIEvents={documentsUIEvents}
                >
                    <DocumentEditDialog/>
                    <DocumentDeleteDialog/>
                    <DocumentQrCodeDialog/>

                    <DocumentsList dealer={selectedDealer}/>
                </DocumentsUIProvider>
            </div>

            <div className="col-lg-12 col-xxl-12">
                <SignedDocumentsGraph dealer={selectedDealer} className="card-stretch gutter-b"/>
            </div>
            {(userHasHst(user) || showDealerStatistics) && <>
                <div className="col-lg-6 col-xxl-4">
                    <SmsGraph dealer={selectedDealer} className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <SpaceGraph dealer={selectedDealer} className="card-stretch gutter-b"/>
                </div>
                <div className="col-lg-6 col-xxl-4">
                    <ArchivedSpaceGraph dealer={selectedDealer} className="card-stretch gutter-b"/>
                </div>
            </>}

        </div>

    </>;
}

export default injectIntl(Dashboard);
