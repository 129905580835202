import {getLegalEntitiesListForUser} from "./DealersHelpers";

function filterDocumentTypesByLegalEntities(documentTypes, legalEntities) {

    return documentTypes.filter((documentTypeExt) => {
        let result = false;

        const documentTypeLegalEntities = documentTypeExt.legalEntities;

        for (let i = 0; i < documentTypeLegalEntities.length; i++) {
            const documentLegalEntity = documentTypeLegalEntities[i];
            const foundLegalEntity = legalEntities.find(legalEntity => legalEntity === documentLegalEntity);
            if (foundLegalEntity) {
                result = true;
            }
        }
        return result;
    })
}

export function getDocumentTypesFromFolder(folderType, folderTypesExt, dealers, user) {
    if (folderType && folderTypesExt && dealers && user) {
        const legalEntities = getLegalEntitiesListForUser(user, dealers);
        const filteredFolderTypeExt = folderTypesExt.find(folderTypeExt => folderTypeExt.slug === folderType);
        return filterDocumentTypesByLegalEntities(filteredFolderTypeExt.documentTypes, legalEntities);
    }
    return [];
}

export function getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt, dealers, user) {
    if (documentType && folderType && folderTypesExt && dealers && user) {
        const documentTypes = getDocumentTypesFromFolder(folderType, folderTypesExt, dealers, user);
        return documentTypes.find(documentTypeExt => documentType.toUpperCase() === documentTypeExt.slug.toUpperCase());
    }
    return false;
}

export function getFieldsFromDocumentType(documentType, folderType, folderTypesExt, dealers, user) {
    if (documentType && folderType && folderTypesExt && dealers && user) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt, dealers, user);
        return documentTypeExt.fields.map((documentTypeField) => documentTypeField.field);
    }
    return [];
}

export function getDocumentTypesFilteredByFolderType(folderType, folderTypesExt, dealers, user, excludeWizard = true) {
    if (folderType && folderTypesExt && dealers && user) {
        const documentTypes = getDocumentTypesFromFolder(folderType, folderTypesExt, dealers, user);
        return documentTypes
            .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0))
            .filter((documentTypeExt) => excludeWizard ? documentTypeExt.visibleInWizard : true)
            .map((documentTypeExt) => documentTypeExt.slug);
    }
    return [];
}

export function getDocumentTypeField(documentType, folderType, folderTypesExt, field, dealers, user) {
    if (documentType && folderType && folderTypesExt && dealers && user) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt, dealers, user);
        return documentTypeExt.fields.find((documentTypeField) => documentTypeField.field === field);
    }
    return null;
}

export function documentTypeHasField(documentType, folderType, folderTypesExt, field, dealers, user) {
    return getFieldsFromDocumentType(documentType, folderType, folderTypesExt, dealers, user).includes(field);
}

export function documentTypeHasFieldRequired(documentType, folderType, folderTypesExt, field, dealers, user) {
    if (documentType && folderType && folderTypesExt && dealers && user) {
        const documentTypeField = getDocumentTypeField(documentType, folderType, folderTypesExt, field, dealers, user);
        if (documentTypeField) {
            return documentTypeField.required === 'true';
        }
    }
    return false;
}

const defaultFields = [
    'ARCHIVABLE',
    'SIGNABLE',
    'SIG_TYPE',
    'FILE',
];

export function documentTypeExtraFields(documentType, folderType, folderTypesExt, dealers, user) {
    if (documentType && folderType && folderTypesExt && dealers && user) {
        const documentTypeExt = getDocumentTypeExtFromDocumentType(documentType, folderType, folderTypesExt, dealers, user);
        if (documentTypeExt && documentTypeExt.fields) {
            return documentTypeExt.fields.filter((field) => !defaultFields.includes(field.field))
        }
    }
    return [];
}

export function getAllExtraFields(folderTypesExt) {
    let extraFields = {};
    if (folderTypesExt) {
        folderTypesExt.forEach((folderType) => {
            folderType.documentTypes.forEach((documentType) => {
                documentType.fields.filter((field) => !defaultFields.includes(field.field)).forEach((field) => {


                    const fieldName = field.field;
                    //const fieldLabel = field.label;
                    const fieldType = field.type;
                    const inputName = `variableFields.${fieldName}`;

                    extraFields[fieldName] = {
                        'name': fieldName,
                        'type': fieldType,
                        'inputName': inputName
                    }
                });

            })
        });
    }
    return extraFields;
}
