import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader} from "../../../../_intesys/_partials/controls";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    BRAND_BMW_MOTORRAD,
    getBrandIntlIdFromValue,
    getBrandLogo,
    getDocumentTypeLabelForCreateButton,
    getDocumentTypesFilteredByFolderType, getFolderLabelFromType,
    getUserFoldersWithWritePermission
} from "../../../../_intesys/_helpers";
import {FormattedMessage, injectIntl} from "react-intl";
import {Dropdown} from "react-bootstrap";
import {documentsSlice} from "../../../modules/App/_redux/documents/documentsSlice";

export function BrandDocumentsButtons({
                                          history,
                                          brand,
                                          className,
                                          intl
                                      }) {

    const title = intl.formatMessage({id: getBrandIntlIdFromValue(brand)});

    let mainColor = brand;

    const {user, dealers, folderTypesExt} = useSelector(
        (state) => ({
            user: state.auth.user,
            dealers: state.dealers.dealers,
            folderTypesExt: state.configurations.folderTypesExt,
        }),
        shallowEqual
    );


    const userFolderTypes = getUserFoldersWithWritePermission(user, brand, folderTypesExt);

    const [selectedWizardUrl, setSelectedWizardUrl] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedWizardUrl && history) {
            dispatch(documentsSlice.actions.resetWizardState(undefined));
            history.push(selectedWizardUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedWizardUrl, dispatch]);

    const getButtons = (folderType) => {

        if (folderType && folderTypesExt && dealers && user) {
            const documentTypes = getDocumentTypesFilteredByFolderType(folderType, folderTypesExt, dealers, user);
            let dashBoardButtons = documentTypes.filter((documentType) => (documentType !== "OTHER_IDENTITY" && documentType !== "OTHER_DRIVING_LICENSE"));

            return dashBoardButtons.map((documentType) => {
                    const label = getDocumentTypeLabelForCreateButton(intl, brand, folderType, documentType, folderTypesExt, dealers, user);
                    const key = `${brand}_${folderType}_${documentType}`;
                    const url = `/app/wizard/${brand}/${folderType}/${documentType}`.toLowerCase();
                    return (
                        <Dropdown.Item eventKey={url} key={key}
                                       className={'d-block dropdown-item-enabled ' + key.toLowerCase()}>{label}</Dropdown.Item>
                    )
                }
            );
        }
    };

    return (
        <Card className={`bg-gray-100 ${className}`}>
            <CardHeader
                title={
                    <span
                        className={"font-weight-bolder text-white"}
                    >
                        {getBrandLogo(brand, intl, null, "40px", true)} {brand === BRAND_BMW_MOTORRAD ? "" : title}
                    </span>
                }
                className={`bg-${mainColor}`}
            >
            </CardHeader>
            <CardBody className={"d-flex flex-column"}>

                {userFolderTypes && userFolderTypes.map((folderType) =>
                    <div key={folderType}>

                        <Dropdown
                            drop="down"
                            onSelect={(eventKey, event) => {
                                if (eventKey) {
                                    setSelectedWizardUrl(eventKey);
                                }
                            }}
                            className={`dropdown-${brand}`}
                        >
                            <Dropdown.Toggle
                                variant={null}
                                className={`btn btn-${mainColor}  font-weight-bolder dropdown-toggle px-5 mb-4 d-block`}
                                style={{width: "100%"}}
                                id="dropdown-toggle-top"
                            >
                                {getFolderLabelFromType(folderType, folderTypesExt)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                className={`dropdown-menu dropdown-menu-right dropdown-menu-${mainColor}`}
                                style={{width: "100%"}}
                            >
                                <Dropdown.Item eventKey="label"
                                               className={`text-uppercase font-weight-bold font-size-sm text-${mainColor}`}
                                               key={'label'} disabled={true}>
                                    <FormattedMessage id={"DASHBOARD.BUTTONS.CREATE_DOCUMENT.DROPDOWN_LABEL"}/>
                                </Dropdown.Item>
                                {getButtons(folderType)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                )}
            </CardBody>
        </Card>
    );
}

export default injectIntl(BrandDocumentsButtons);
