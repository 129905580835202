import {Modal} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";
import DocumentEditDialogHeader from "./DocumentEditDialogHeader";
import DocumentEditDialogForm from "./DocumentEditDialogForm";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../_redux/documents/documentsActions";
import {useDocumentsUIContext} from "../DocumentsUIContext";
import {documentTypeExtraFields, STATE_CREATED, STATE_CREATING, STATE_ERROR, STATE_FETCHING, STATE_UPDATED, STATE_UPDATING, userCanCreateDocument, userCanUpdateDocument} from "../../../../../../_intesys/_helpers";
import {injectIntl} from "react-intl";
import Notifier from "../../../../Common/notifier/Notifier";
import * as foldersActions from "../../../_redux/folders/foldersActions";

function DocumentEditDialog({
                                intl,
                            }) {
    const documentsUIContext = useDocumentsUIContext();
    const documentsUIProps = useMemo(() => {
        return {
            id: documentsUIContext.selectedId,
            documentId: documentsUIContext.documentId,
            initDocument: documentsUIContext.initDocument,
            showEditDocumentDialog: documentsUIContext.showEditDocumentDialog,
            closeEditDocumentDialog: documentsUIContext.closeEditDocumentDialog,
            folderType: documentsUIContext.folderType,
            folderId: documentsUIContext.folderId,
        };
    }, [documentsUIContext]);

    const dispatch = useDispatch();

    const {isLoading, documentForEdit, folderForEdit, error, isCreating, isCreated, isUpdating, isUpdated, isError, folderTypesExt, dealers} = useSelector(
        (state) => ({
            isLoading: state.documents.itemState === STATE_FETCHING,
            documentForEdit: state.documents.itemForEdit,
            folderForEdit: state.folders.itemForEdit,
            error: state.documents.itemError,
            isCreating: state.documents.itemState === STATE_CREATING,
            isCreated: state.documents.itemState === STATE_CREATED,
            isUpdating: state.documents.itemState === STATE_UPDATING,
            isUpdated: state.documents.itemState === STATE_UPDATED,
            isError: state.documents.itemState === STATE_ERROR,
            folderTypesExt: state.configurations.folderTypesExt,
            dealers: state.dealers.dealers,
        }),
        shallowEqual
    );

    const {folder} = useSelector(
        (state) => ({
            folder: state.folders.itemForEdit,
        }),
        shallowEqual
    );

    const {user} = useSelector(state => state.auth);


    useEffect(() => {
        if (documentsUIProps.showEditDocumentDialog) {
            if (documentsUIProps.id) {
                dispatch(actions.fetchDocument(documentsUIProps.id));
            }
        }
    }, [documentsUIProps.showEditDocumentDialog, documentsUIProps.id, dispatch]);

    useEffect(() => {
        if (documentsUIProps.showEditDocumentDialog) {
            if (documentsUIProps.id) {
                if (documentForEdit) {
                    dispatch(foldersActions.fetchFolder(documentForEdit.folderId));
                }
            } else {
                dispatch(foldersActions.fetchFolder(documentsUIProps.folderId));
            }
        }
    }, [documentsUIProps.showEditDocumentDialog, documentsUIProps.id, documentsUIProps.folderId, documentForEdit, dispatch]);


    const saveDocument = (document) => {
        if (!documentsUIProps.id) {

            // filters the variable fields keeping only those associated with the document type
            const fieldsToKeep = documentTypeExtraFields(document.type, folder.type, folderTypesExt, dealers, user).map((field) => 'variableFields_' + field.field);
            let documentFiltered = {};
            for (const documentKey in document) {

                if (documentKey.startsWith("variableFields_")) {
                    if (fieldsToKeep.indexOf(documentKey) > -1) {
                        documentFiltered[documentKey] = document[documentKey];
                    }
                } else {
                    documentFiltered[documentKey] = document[documentKey];
                }
            }
            dispatch(actions.createDocument(documentFiltered));
        } else {
            // update
            dispatch(actions.updateDocument(document));
        }

    }
    useEffect(() => {
        if (isCreated || isUpdated) {
            documentsUIProps.closeEditDocumentDialog();
        }
    }, [isCreated, isUpdated, documentsUIProps]);

    const [showSaveButton, setShowSaveButton] = useState(false);

    useEffect(() => {
        if (documentsUIProps.showEditDocumentDialog) {
            if (documentsUIProps.id) {
                //edit
                if (userCanUpdateDocument(user, folder, documentForEdit)) {
                    setShowSaveButton(true);
                }
            } else {
                //new
                if (userCanCreateDocument(user, folder)) {
                    setShowSaveButton(true);
                }
            }
        }
    }, [documentsUIProps.showEditDocumentDialog, documentsUIProps.id, documentForEdit, folder, user]);

    return (
        <Modal
            show={documentsUIProps.showEditDocumentDialog}
            onHide={documentsUIProps.closeEditDocumentDialog}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
        >
            <DocumentEditDialogHeader id={documentsUIProps.id}/>
            <Notifier
                object={"DOCUMENTS"}
                isCreating={isCreating}
                isUpdating={isUpdating}
                isCreated={isCreated}
                isUpdated={isUpdated}
                isError={isError}
                error={error}
            />
            <DocumentEditDialogForm
                saveDocument={saveDocument}
                isLoading={isLoading}
                isCreating={isCreating}
                isUpdating={isUpdating}
                document={documentForEdit || documentsUIProps.initDocument}
                documentError={error}
                onHide={documentsUIProps.closeEditDocumentDialog}
                folderType={folderForEdit?.type || documentsUIProps.folderType}
                showSaveButton={showSaveButton}
            />
        </Modal>
    );
}

export default injectIntl(DocumentEditDialog);
