import {
    getUserFoldersWithWritePermission,
    userHasOnlyReadOnlyPermissions,
    userHasWritePermissionOverBrandAndFolderType
} from "./PermissionsHelpers";
import * as Yup from "yup";
import {folderTypeHasField, folderTypeHasFieldRequired} from "./FolderTypesHelpers";

export function userCanCreateFolder(user) {
    if (user) {
        return !userHasOnlyReadOnlyPermissions(user);
    }
    return false;
}

export function userCanUpdateFolder(user, folder, folderTypesExt) {
    if (folder && user && folderTypesExt) {
        let hasEditableFields = false;
        const folderType = folder.type;

        if (folderTypeHasField(folderType, folderTypesExt, "BUSINESS_ID")) {
            if (businessIdIsEditable(folder)) {
                hasEditableFields = true;
            }
        }
        if (folderTypeHasField(folderType, folderTypesExt, "PLATE")) {
            if (plateFieldIsEditable(folder)) {
                hasEditableFields = true;
            }
        }
        if (folderTypeHasField(folderType, folderTypesExt, "VIN")) {
            if (vinFieldIsEditable(folder)) {
                hasEditableFields = true;
            }
        }
        if (folderTypeHasField(folderType, folderTypesExt, "NOTES")) {
            if (notesFieldIsEditable(folder)) {
                hasEditableFields = true;
            }
        }
        return ((folder.numberOfDocuments <= 0) || hasEditableFields) && userHasWritePermissionOverBrandAndFolderType(user, folder.brand, folder.type);
    }
    return false;
}

export function businessIdIsEditable(folder) {
    return !folder.businessId;
}

export function plateFieldIsEditable(folder) {
    return !folder.plate;
}

export function vinFieldIsEditable(folder) {
    return !folder.vin;
}

export function processDateFieldIsEditable(folder) {
    return !folder.processDate;
}

export function notesFieldIsEditable(folder) {
    return !folder.notes;
}

export function userCanDeleteFolder(user, folder, folderTypesExt) {
    return userCanUpdateFolder(user, folder, folderTypesExt);
}


export function getFoldersValidationSchema(brands, folderTypesExt, user, namePrefix = "",) {
    return Yup.object().shape({
        brand: Yup.string().required().oneOf(brands).max(255),
        type: Yup.string().required().max(255).when(`${namePrefix}brand`, (brand, schema) => {
            return schema.oneOf(getUserFoldersWithWritePermission(user, brand, folderTypesExt));
        }),
        processDate: Yup.date().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "PROCESS_DATE") ? schema.required() : schema.nullable();
        }),
        businessId: Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "BUSINESS_ID") ? schema.required() : schema;
        }),
        plate: Yup.string().max(255).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "PLATE") ? schema.required() : schema;
        }),
        vin: Yup.string().max(17).ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "VIN") ? schema.required() : schema;
        }),
        notes: Yup.string().ensure().when(`${namePrefix}type`, (type, schema) => {
            return folderTypeHasFieldRequired(type, folderTypesExt, "NOTES") ? schema.required() : schema.nullable();
        }),
    });
}
